import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
//import './Slider.css'   

function Slider() {
    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/Home/images/banner/banner.jpg"
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img 
                        className="d-block w-100"
                        src="/Home/images/banner/banner.jpg"
                        alt="Second slide"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Slider;

/*Carousel refer from this website https://react-bootstrap.netlify.app/docs/components/carousel*/