import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const FAQs = () => {
    return (
		<>
			<div className="page-content">
			<div className="breadcrumbspage-title">
				<div className="container">
					<h2 className="title">Frequently Asked Questions (FAQs)</h2>
					<div className="breadcrumbs">
						<span><a href="/" rel="home">Home</a></span>
						<span><i className="fa fa-angle-right"></i></span>
						<span className="active">Frequently Asked Questions (FAQs)</span>
					</div>
				</div>
			</div>

			<div className="section">
				<div className="container">
					<div className="row no-gutters">
						<div className="col-xl-12">
							<div id="faqAccordion1" className="faq-accordion" data-children=".faq-item">
								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem1" aria-expanded="true"><span>1.</span><span>What services does Twinbrain Advertising Limited offer?</span></p>
									<div id="faqItem1" className="collapse show faq-item-content" role="tabpanel">
										<div>
											<p>
												We provide a comprehensive range of advertising services, including strategic campaign planning, creative concept development, digital marketing, print and media advertising, event promotion, public relations, market research, brand consultation, social media management, and analytics.
											</p>
										</div>
									</div>
								</div>
								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem2" aria-expanded="false" className="collapsed"><span>2.</span><span>How can I contact Twinbrain Advertising Limited?</span></p>
									<div id="faqItem2" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												You can reach us via email at Lexcspl@gmail.com. Our registered address is H.NO 291 TELI SAMAJ BHAWAN ITWARI MIRCHI BAJAR NAGPUR NAGPUR, Maharashtra, 440002, IN.
											</p>
										</div>
									</div>
								</div>
								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem3" aria-expanded="false" className="collapsed"><span>3.</span><span>Is Twinbrain Advertising Limited currently active?</span></p>
									<div id="faqItem3" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												Yes, our current status is active, reflecting our ongoing commitment to delivering top-notch services in the advertising industry.
											</p>
										</div>
									</div>
								</div>
								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem4" aria-expanded="false" className="collapsed"><span>4.</span><span>Who are the directors of Twinbrain Advertising Limited?</span></p>
									<div id="faqItem4" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												The directors of our company are Nikhil Bhimkumar Wadhwani, Kaustubh Vijay Singh Nayak, and Girish Ramchand Kishnani.
											</p>
										</div>
									</div>
								</div>

								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem5" aria-expanded="false" className="collapsed"><span>5.</span><span>What is the Corporate Identification Number (CIN) of Twinbrain Advertising Limited?</span></p>
									<div id="faqItem5" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												Our Corporate Identification Number is U74999MH2022PLC376627, and the registration number is 376627.
											</p>
										</div>
									</div>
								</div>

								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem6" aria-expanded="false" className="collapsed"><span>6.</span><span>When was Twinbrain Advertising Limited incorporated?</span></p>
									<div id="faqItem6" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												We were incorporated on 11 February 2022.
											</p>
										</div>
									</div>
								</div>

								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem7" aria-expanded="false" className="collapsed"><span>7.</span><span>When was the last Annual General Meeting (AGM) held?</span></p>
									<div id="faqItem7" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												As of now, the date for the last AGM is not available.
											</p>
										</div>
									</div>
								</div>
								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem8" aria-expanded="false" className="collapsed"><span>8.</span><span>Where can I find information about Twinbrain Advertising Limited's financial status?</span></p>
									<div id="faqItem8" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												The balance sheet details are not available as per records from the Ministry of Corporate Affairs (MCA).
											</p>
										</div>
									</div>
								</div>
								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem9" aria-expanded="false" className="collapsed"><span>9.</span><span>Can I see examples of your previous work?</span></p>
									<div id="faqItem9" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												Certainly! We have a portfolio showcasing successful campaigns and projects. Feel free to reach out, and we'll be happy to share relevant examples.
											</p>
										</div>
									</div>
								</div>
								<div className="faq-item">
									<p data-toggle="collapse" data-parent="#faqAccordion1" href="#faqItem10" aria-expanded="false" className="collapsed"><span>10.</span><span>How can Twinbrain Advertising help my business stand out in the market?</span></p>
									<div id="faqItem10" className="collapse faq-item-content" role="tabpanel" >
										<div>
											<p>
												We tailor our advertising strategies to meet your unique business objectives, leveraging creativity, innovation, and strategic planning to ensure your brand stands out and resonates with your target audience.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-xl-12">
							<p>If you have additional questions or require more information, please don't hesitate to contact us.</p>
						</div>
					</div>
				</div>
			</div>
			</div>
        </>
    )
}

export default FAQs;