import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const About = () => {
    return (    
        <>
            <div className="page-content">
                <div className="breadcrumbspage-title">
                    <div className="container">
                        <h2 className="title">About Us</h2>
                        <div className="breadcrumbs">
                            <span><a href="/" rel="home">Home</a></span>
                            <span><i className="fa fa-angle-right"></i></span>
                            <span className="active">About Us</span>
                        </div>
                    </div>
                </div>

                <div className="section page-content-first about-section mb-0 mt-0">
                    <div className="container">
                        <div className="text-center mb-2  mb-md-3 mb-lg-2">
                            <h1 className="h1mainHeading">About Us</h1>
                            <div className="h-decor"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="images-column col-lg-6 col-md-12 col-sm-12 mb-0">
                                <img src="/Home/images/about/aboutus.jpg" alt="" className="img-fluid aboutImageNew" />
                            </div>


                            <div className="col-lg-6 mt-3 mt-lg-0">
                                <p>Welcome to Twinbrain Advertising Limited, a dynamic player in the advertising industry. Established on 11 February 2022 and headquartered in Nagpur, our company is a Non-govt entity registered with the Registrar of Companies, Mumbai. With an authorized share capital of Rs. 1,000,000 and a paid-up capital of Rs. 675,000, we are committed to excellence in a diverse range of business activities.</p>
                                <p>Driven by a visionary team, our directors, Nikhil Bhimkumar Wadhwani, Kaustubh Vijay Singh Nayak, and Girish Ramchand Kishnani, steer Twinbrain Advertising towards innovation and success.</p>
                                <p>Our Corporate Identification Number (CIN) is U74999MH2022PLC376627, and we operate with registration number 376627. For any inquiries, you can reach us at Lexcspl@gmail.com. Our registered address is H.NO 291 TELI SAMAJ BHAWAN ITWARI MIRCHI BAJAR NAGPUR NAGPUR, Maharashtra, 440002, IN.</p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="section about-section-bottom mt-0 pt-0 pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <p>Twinbrain Advertising Limited is currently active, reflecting our ongoing commitment to delivering top-notch services in the ever-evolving realm of advertising. </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="section">
                    <div className="container">
                        <div className="before-after">
                            <div className="before-after-image">
                                <img src="/Home/images/mission.png" alt="" />
                            </div>
                            <div className="before-after-text">
                                <h5 className="before-after-name">Mission</h5>
                                <p>At Twinbrain Advertising Limited, our mission is to redefine the advertising landscape by delivering innovative, impactful, and result-oriented solutions. We strive to empower businesses to reach their full potential through strategic and creative campaigns. Committed to excellence, we aim to be the catalyst for our clients' success, ensuring their messages resonate and leave a lasting impression in the hearts of their target audience. </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section page-content-first about-section mb-0 mt-0">
                    <div className="container">
                        <div className="text-center mb-2  mb-md-3 mb-lg-2">
                            <h1 className="h1mainHeading">Values</h1>
                            <div className="h-decor"></div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 mt-lg-0">
                                <div className="icn-text">
                                    <div className="icn-text-simple">
                                        <img src="Home/images/about/innovation.png" className="icon" />
                                    </div>
                                    <div>
                                        <h5 className="icn-text-title">Innovation</h5>
                                        <p>We embrace creativity and continuously seek groundbreaking ideas to stay at the forefront of the advertising industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-lg-0">
                                <div className="icn-text">
                                    <div className="icn-text-simple"> <img src="Home/images/about/intigrity.png" className="icon" /></div>
                                    <div>
                                        <h5 className="icn-text-title"> Integrity </h5>
                                        <p> We uphold the highest ethical standards in all our interactions, fostering trust and transparency with our clients, partners, and stakeholders. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-lg-0">
                                <div className="icn-text">
                                    <div className="icn-text-simple"><img src="Home/images/about/Collaboration.png" className="icon" /></div>
                                    <div>
                                        <h5 className="icn-text-title"> Collaboration </h5>
                                        <p> We believe in the power of teamwork and collaboration. By working together, we achieve greater outcomes and surpass expectations. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-lg-0">
                                <div className="icn-text">
                                    <div className="icn-text-simple"><img src="Home/images/about/approach.png" className="icon" /></div>
                                    <div>
                                        <h5 className="icn-text-title"> Client-Centric Approach </h5>
                                        <p> Our clients are at the heart of everything we do. We are dedicated to understanding their unique needs and tailoring solutions that drive tangible results. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-lg-0">
                                <div className="icn-text">
                                    <div className="icn-text-simple"><img src="Home/images/about/excellance.png" className="icon" /></div>
                                    <div>
                                        <h5 className="icn-text-title"> Excellence </h5>
                                        <p> We pursue excellence in every aspect of our work. From concept to execution, we are committed to delivering unparalleled quality and value to our clients. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 mt-lg-0">
                                <div className="icn-text">
                                    <div className="icn-text-simple"><img src="Home/images/about/adaptation.png" className="icon" /></div>
                                    <div>
                                        <h5 className="icn-text-title"> Adaptability </h5>
                                        <p> In the ever-evolving advertising landscape, we remain agile and adaptable, leveraging emerging trends and technologies to provide cutting-edge solutions. </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 mt-lg-0">
                                <div className="icn-text">
                                    <div className="icn-text-simple"><img src="Home/images/about/social.png" className="icon" /></div>
                                    <div>
                                        <h5 className="icn-text-title"> Social Responsibility </h5>
                                        <p> We are conscientious corporate citizens, committed to making a positive impact on society and the environment through responsible business practices. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;