import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";
import '.././custom.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contactus = () => {
     
    const form = useRef();
     
    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        reset 
    } = useForm();

    const onSubmit = (data) => {
         
        console.log(data);
        console.log(form.current);
        emailjs.sendForm('service_ekfbfql', 'template_x0kwowi', form.current, 'EtX3qSKvHXhMhLjaE')
            .then((result) => {
                toast.success("We will contact you as soon", {
                    position: "top-center"
                });
                reset(); 
            }, (error) => {
                toast.error("Sorry, Please try after sometime", {
                    position: "top-center"
                });
            }); 
    } 
    return (
        <>
            <div className="page-content">
                <div className="breadcrumbspage-title">
                    <div className="container">
                        <h2 className="title">Contact Us</h2>
                        <div className="breadcrumbs">
                            <span><a href="/" rel="home">Home</a></span>
                            <span><i className="fa fa-angle-right"></i></span>
                            <span className="active">Contact Us</span>
                        </div>
                    </div>
                </div>

                <div className="section page-content-first mt-0">
                    <div className="container">
                        <div className="text-center mb-2  mb-md-3 mb-lg-4">
                            <h2>Contact Us</h2>
                            <div className="h-decor"></div>
                            <div className="text-center mt-2">
                                <p>Feel free to drop us a message or visit our office. We're here to assist you and discuss how Twinbrain Advertising Limited can elevate your brand presence and meet your advertising needs. Your success is our priority, and we look forward to the opportunity to collaborate with you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="contactInfo">
                                    <div className="title-wrap">
                                        <h5>Registered Address</h5>
                                        <div className="h-decor"></div>
                                    </div>
                                    <ul className="icn-list-lg">
                                        <li><i className="icon-placeholder2"></i>Plot No. 78, Opposite Eastern Sports Club, Wardhman Nagar, Nagpur, Maharashtra, 440002, INDIA.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 mt-md-0">
                                <div className="contactInfo">
                                    <div className="title-wrap">
                                        <h5>Email</h5>
                                        <div className="h-decor"></div>
                                    </div>
                                    <p>For general inquiries and information:</p>
                                    <ul className="icn-list-lg">
                                        <li><i className="icon-black-envelope"></i><a href="mailto:twinbrainadvertisinglimited@gmail.com" className="text-decoration-none">twinbrainadvertisinglimited@gmail.com</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4 mt-3 mt-md-0">
                                <div className="contactInfo">
                                    <div className="title-wrap">
                                        <h5>Connect with Us on Social Media</h5>
                                        <div className="h-decor"></div>
                                    </div>
                                    <div className="col-sm-auto ml-auto mt-0">
                                        <div className="footer-social d-md-none d-lg-block">
                                            <a href="https://www.facebook.com/TwinbrainAdvertising" target="blank" className="hovicon"><i className="fab fa-facebook color-facebook"></i></a>
                                            <a href="https://twitter.com/TwinbrainAds" target="blank" className="hovicon"><i className="fab fa-twitter color-twitter"></i></a>
                                            <a href="https://www.instagram.com/twinbrain_advertising/" target="blank" className="hovicon"><i className="fab fa-instagram color-instagram"></i></a>
                                            <a href="https://www.linkedin.com/company/twinbrain-advertising-limited" target="blank" className="hovicon"><i className="fab fa-linkedin color-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section mb-0 bg-grey">
                    <div className="banner-contact-us" style={{ backgroundImage: 'url(/Home/images/contact-bg.png)' }}>
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-sm-6 col-lg-6 col-sm-12 order-2 order-sm-1 mt-3 mt-md-0 text-center text-md-left d-flex align-items-end">
                                    <img src="/Home/images/banner-callus.png" alt="" className="shift-left w-100" />
                                </div>
                                <div className="col-sm-6 col-lg-6 col-sm-12 order-1 order-sm-2 d-flex">
                                    <div className="pt-2 pt-lg-6 p-3">
                                        <h2 data-title="Looking for a Certified Doctor?"><span>Get In Touch
                                            <br className="d-lg-none" />
                                            <span className="theme-color">With Us</span></span></h2>
                                        <p>Have a question, a project in mind, or just want to say hello? We'd love to hear from you. Here are the ways you can get in touch:</p>
                                        <form ref={form} onSubmit={handleSubmit(onSubmit)} className="contact-form" id="contactform" method="post" noValidate="novalidate">
                                            <div className="successform">
                                                <p>Your message was sent successfully!</p>
                                            </div>
                                            <div className="errorform">
                                                <p>Something went wrong, try refreshing and submitting the form again.</p>
                                            </div>
                                            <div>
                                                <input type="text" className="form-control" name="from_name" id="from_name"
                                                    autoComplete="off"
                                                    placeholder="Your name*"
                                                    {...register("from_name", { required: true })}
                                                />
                                                <span className="errormsg">
                                                    {errors.name?.type === "required" && "Name is required"}
                                                </span>
                                            </div>
                                            <div className="mt-15">
                                                <div>
                                                    <input
                                                        autoComplete="off"
                                                        type="text" className="form-control" name="from_contact" id="from_contact" placeholder="Your Phone*"
                                                        
                                                        {...register("from_contact", {
                                                            required: true,
                                                            minLength: 10,
                                                            maxLength: 10,
                                                        })}
                                                    />
                                                    <span className="errormsg">
                                                        {errors.number?.type === "required" && "number is required"}
                                                        {errors.number?.type === "minLength" && "Entered number is less than 10 digits"}
                                                        {errors.number?.type === "maxLength" && "Entered number is not valid Mobile Number"}
                                                    </span>
                                                </div>
                                                <br/>
                                                <div>
                                                    <input type="text" className="form-control" name="from_email" id="from_email"
                                                        placeholder="Email*" autoComplete="off"
                                                        
                                                        {...register("from_email", {
                                                            required: true,
                                                            pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                                                        })}
                                                    />
                                                    <span className="errormsg">
                                                        {errors.email?.type === "required" && "Email is required"}
                                                        {errors.email?.type === "pattern" &&
                                                            "Entered email is in wrong format"}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mt-15">
                                                <textarea
                                                    className="form-control" name="message" id="message"
                                                    placeholder="Enter Message*" autoComplete="off"
                                                    
                                                    {...register("message", { required: true })}
                                                ></textarea>
                                                <span className="errormsg">
                                                    {errors.message?.type === "required" && "message is required"}
                                                </span>
                                            </div>
                                            <div className="mt-3">
                                                <button type="submit" className="btn btn-hover-fill btnSubmit"
                                                    disabled={isSubmitting} ><i className="icon-right-arrow"></i><span>
                                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                                    </span><i className="icon-right-arrow"></i></button>
                                            </div>
                                          
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </>
    )
}
export default Contactus;
