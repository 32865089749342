import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const FooterUser = () => {
    return (
        <>
            <div className="footer mt-0">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row text-center text-md-left align-items-center">
                            <div className="col-sm">
                                Copyright © 2024 <a href="#">Twinbrain Advertisement Limited</a><span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                                <a href="#">Privacy Policy</a> <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span> <a href="/Home/Terms">Terms & Conditions</a>
                            </div>
                            <div className="col-sm-auto ml-auto">
                                <div className="footer-social d-md-none d-lg-block">
                                    <a href="https://www.facebook.com/TwinbrainAdvertising" target="blank" className="hovicon"><i className="fab fa-facebook color-facebook"></i></a>
                                    <a href="https://twitter.com/TwinbrainAds" target="blank" className="hovicon"><i className="fab fa-twitter color-twitter"></i></a>
                                    <a href="https://www.instagram.com/twinbrain_advertising/" target="blank" className="hovicon"><i className="fab fa-instagram color-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/twinbrain-advertising-limited" target="blank" className="hovicon"><i className="fab fa-linkedin color-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterUser;