import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const Investor = () => {
    return (
        <>
            <div className="page-content">
            <div className="breadcrumbspage-title">
                <div className="container">
                    <h2 className="title">Investor</h2>
                    <div className="breadcrumbs">
                        <span><a href="/" rel="home">Home</a></span>
                        <span><i className="fa fa-angle-right"></i></span>
                        <span className="active">Investor</span>
                    </div>
                </div>
            </div>

            <div className="section page-content-first about-section mb-0 mt-0">
                <div className="container">
                    <div className="text-center mb-2  mb-md-3 mb-lg-4">
                        <h2>Invest in Success with TwinBrain Shares!</h2>
                        <div className="h-decor"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <img src="/Home/images/investorImg.jpg" className="img-fluid" />
                        </div>
                        <div className="col-md-8">
                            <div className="doctor-info-name">
                                <h6>Dear Investor,</h6>
                            </div>
                            <p>Unlock growth and potential with TwinBrain - an innovative force in the advertising industry. Invest in our success story and be part of a journey defined by creativity, strategic vision, and financial prosperity.</p>


                            <div className="doctor-info-name">
                                <h6> Why TwinBrain Shares?</h6>
                            </div>
                            <ul className="marker-list-md">
                                <li><i>Proven Track Record: Explore our successful campaigns and robust financial performance.</i></li>
                                <li><i>Visionary Leadership: Led by a dynamic team dedicated to excellence.</i></li>
                                <li><i>Industry Innovation: Stay ahead with our commitment to digital and traditional advertising evolution.</i></li>
                            </ul>


                            <div className="doctor-info-name">
                                <h6>Investment Opportunities:</h6>
                            </div>
                            <ul className="marker-list-md">
                                <li><i>Join us in shaping the future of advertising.</i></li>
                                <li><i>- Own a stake in a company committed to innovation and strategic growth.</i></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section page-content-first about-section mb-0 mt-0">
                <div className="container">
                    <div className="text-center mb-2  mb-md-3 mb-lg-4">
                        <h2>Introducing TwinBrain Digital Insights - Your Gateway to Industry Excellence!</h2>
                        <div className="h-decor"></div>
                        <div className="text-center mt-2">
                            <p>Unlock a world of digital brilliance with TwinBrain's exclusive digital newspaper – TwinBrain Digital Insights. Dive into the latest trends, groundbreaking innovations, and strategic insights shaping the digital landscape. Subscribe now for just Rs. 149 per month and elevate your understanding of the digital business domain.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="text-center mb-2  mb-md-3 mb-lg-4">
                        <h2>Why TwinBrain Digital Insights?</h2>
                        <div className="h-decor"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 mt-lg-0">
                            <div className="investorBox">
                                <div>
                                    <h5 className="icn-text-title">In-Depth Analysis  </h5>
                                    <p>
                                        Gain access to comprehensive analyses of industry trends, providing you with valuable insights to stay ahead of the curve.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="investorBox">
                                <div>
                                    <h5 className="icn-text-title">Expert Opinions  </h5>
                                    <p>Explore interviews and expert opinions from thought leaders and visionaries in the digital business arena. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="investorBox">
                                <div>
                                    <h5 className="icn-text-title">Case Studies  </h5>
                                    <p>Learn from real-world examples with detailed case studies showcasing successful digital strategies and campaigns. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="investorBox">
                                <div>
                                    <h5 className="icn-text-title">Emerging Technologies  </h5>
                                    <p>Stay informed about the latest technologies disrupting the digital landscape and how they can benefit your business. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="investorBox">
                                <div>
                                    <h5 className="icn-text-title">Exclusive Interviews  </h5>
                                    <p>Get up close and personal with key figures in the digital business world through our exclusive interview series. </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="doctor-info-name">
                                <h6>Subscription Details</h6>
                            </div>
                            <ul className="marker-list-md">
                                <li><i>Monthly Subscription: Rs. 149</i></li>
                                <li><i>Payment Options: Convenient online payment methods for a seamless experience.</i></li>
                            </ul>


                            <div className="doctor-info-name">
                                <h6>How to Subscribe</h6>
                            </div>
                            <ul className="marker-list-md">
                                <li><i>Visit [TwinBrainDigitalInsights.com](#) to explore our subscription plans.</i></li>
                                <li><i>Select the monthly plan for just Rs. 149.</i></li>
                                <li><i>Complete the easy online payment process.</i></li>
                            </ul>


                            <div className="doctor-info-name">
                                <h6> Stay Ahead, Stay Informed with TwinBrain Digital Insights</h6>
                            </div>
                            <p>Subscribe today to elevate your digital business acumen and lead with confidence in the ever-evolving digital era. TwinBrain Digital Insights – Your key to staying informed, inspired, and ahead of the digital curve!</p>

                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Investor;
