import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const HeaderUser = () => {
    return (
        <>
            <header className="header">
                <div className="header-quickLinks js-header-quickLinks d-lg-none">
                    <div className="quickLinks-top js-quickLinks-top"></div>
                    <div className="js-quickLinks-wrap-m">
                    </div>
                </div>
                <div className="header-topline d-none d-lg-flex">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-auto d-flex align-items-center">
                                <div className="header-phone"><i className="icon-telephone"></i><a href="#">Call : +91-123-456-7890</a></div>
                                <div className="header-info"><i className="icon-black-envelope"></i><a href="mailto:info@twinbrainadvertising.com">info@twinbrainadvertising.com</a></div>
                            </div>
                            <div className="col-auto ml-auto d-flex align-items-center">
                                <span className="header-social">
                                    <a href="https://www.facebook.com/TwinbrainAdvertising" target="_blank" className="hovicon"><i className="fab fa-facebook color-facebook"></i></a>
                                    <a href="https://twitter.com/TwinbrainAds" target="_blank" className="hovicon"><i className="fab fa-twitter color-twitter"></i></a>
                                    <a href="https://www.instagram.com/twinbrain_advertising/" target="_blank" className="hovicon"><i className="fab fa-instagram color-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/twinbrain-advertising-limited" target="_blank" className="hovicon"><i className="fab fa-linkedin color-linkedin"></i></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-content">
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <button className="navbar-toggler collapsed mt-3" data-toggle="collapse" data-target="#navbarNavDropdown">
                                <span className="icon-menu"></span>
                            </button>
                            <div className="col-lg-auto col-lg-2 d-flex align-items-lg-center">
                                <a href="/" className="header-logo">
                                    <img src="/Content/Home/images/logo.png" alt="" className="img-fluid"/></a>
                            </div>
                            <div className="col-lg ml-auto header-nav-wrap">
                                <div className="header-nav js-header-nav">
                                    <nav className="navbar navbar-expand-lg btco-hover-menu">
                                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                                            <ul className="navbar-nav">
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/Home/Index">Home</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="/Home/AboutUs" className="nav-link">About Us</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/Home/Portfolio ">Portfolio </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/Home/Blog">Blog</a>
                                                </li>

                                                <li className="nav-item">
                                                    <a className="nav-link" href="/Home/Investor">Investor</a>
                                                </li>

                                                <li className="nav-item">
                                                    <a className="nav-link" href="/Home/Faq">Faq</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/Home/Services">Services</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" href="/Home/ContactUs">Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderUser;