import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
//import  Carousel from 'react-bootstrap';
import './Testimonial.css'

const Testimonial = () => {
    return (
        <div className="section bg-grey">
            <div className="container">
                <div className="container">
                    <div className="text-center mb-2  mb-md-3 mb-lg-4">
                        <h2 className="h1">What Our <span className="theme-color" style={{ color: '#c8da2b' }} > Clients Say</span></h2>
                        <div className="h-decor"></div>
                        <div className="text-center mt-2">
                            <p>Twinbrain Advertising Limited has had the privilege of working with a diverse range of clients, and their satisfaction speaks volumes about our commitment to excellence. Here are some testimonials from our valued clients:</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="testimonial-card">
                            <Carousel>
                                <Carousel.Item>
                                    <div>
                                        <div class="testimonial-card-text"> "Twinbrain Advertising has been instrumental in shaping our brand identity. Their innovative campaigns and strategic approach have significantly contributed to our market presence. A reliable partner in every sense!" </div>
                                        <div class="testimonial-card-author"><span class="testimonial-card-position"> XYZ Tech Solutions </span></div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div>
                                        <div className="testimonial-card-text"> "The creativity and attention to detail exhibited by Twinbrain Advertising are truly commendable. They understand the pulse of our industry and have consistently delivered captivating campaigns that resonate with our audience." </div>
                                        <div className="testimonial-card-author"><span className="testimonial-card-position"> Vogue Trends </span></div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div>
                                        <div class="testimonial-card-text"> "Twinbrain Advertising goes beyond traditional advertising; they genuinely care about our mission. Their campaigns have not only increased our visibility but also connected us with individuals seeking a healthier lifestyle." </div>
                                        <div class="testimonial-card-author"><span class="testimonial-card-position"> Healthy Living Clinic </span></div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div>
                                        <div class="testimonial-card-text"> "Working with Twinbrain Advertising has been a delightful experience. Their expertise in promoting travel and hospitality has significantly boosted our bookings. They understand the essence of our brand and translate it into compelling narratives." </div>
                                        <div class="testimonial-card-author"><span class="testimonial-card-position"> Wanderlust Resorts </span></div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div>
                                        <div class="testimonial-card-text"> "In the competitive financial sector, Twinbrain Advertising stands out. Their strategic approach to marketing has helped us communicate complex financial services in a clear and engaging manner. Highly recommended!" </div>
                                        <div class="testimonial-card-author"><span class="testimonial-card-position"> Capital Trust Bank </span></div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div>
                                        <div class="testimonial-card-text"> "Twinbrain Advertising understands the unique challenges in the education sector. Their campaigns have not only attracted new students but have also reinforced our institution's values. A trusted partner in our journey." </div>
                                        <div class="testimonial-card-author"><span class="testimonial-card-position"> Global Academy of Learning </span></div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        <p>These testimonials reflect our commitment to delivering exceptional results and building lasting relationships with our clients. At Twinbrain Advertising Limited, we thrive on the success stories of those we serve.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;

/*Carousel refer from this website https://react-bootstrap.netlify.app/docs/components/carousel*/