import React, { Component, useState } from 'react';

import { Link, NavLink } from 'react-router-dom';
import './NavMenu.css';

const NavMenu = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <div className="shop-page">
            <header className="header">
                <div className="header-quickLinks js-header-quickLinks d-lg-none">
                    <div className="quickLinks-top js-quickLinks-top"></div>
                    <div className="js-quickLinks-wrap-m">
                    </div>
                </div>
                <div className="header-topline d-none d-lg-flex">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-auto d-flex align-items-center">
                                <div className="header-phone"><i className="icon-telephone"></i><a href="#">Call : +91 9595050300</a></div>
                                <div className="header-info"><i className="icon-black-envelope"></i><a href="mailto:twinbrainadvertisinglimited@gmail.com">twinbrainadvertisinglimited@gmail.com</a></div>
                            </div>
                            <div className="col-auto ml-auto d-flex align-items-center">
                                <span className="header-social">
                                    <a href="https://www.facebook.com/TwinbrainAdvertising" target="_blank" className="hovicon"><i className="fab fa-facebook color-facebook"></i></a>
                                    <a href="https://twitter.com/TwinbrainAds" target="_blank" className="hovicon"><i className="fab fa-twitter color-twitter"></i></a>
                                    <a href="https://www.instagram.com/twinbrain_advertising/" target="_blank" className="hovicon"><i className="fab fa-instagram color-instagram"></i></a>
                                    <a href="https://www.linkedin.com/company/twinbrain-advertising-limited" target="_blank" className="hovicon"><i className="fab fa-linkedin color-linkedin"></i></a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-content">
                    <div className="container">
                        <div className="container">
                            <div className="row align-items-lg-center">
                                <button
                                    className={`mt-1 navbar-toggler collapsed ${isMobileMenuOpen ? 'active' : ''}`}
                                    onClick={toggleMobileMenu}>
                                    <span className="icon-menu float-right"></span>
                                </button>
                                <div className="col-lg-auto col-lg-2 d-flex align-items-lg-center">
                                    <a href="/" className="header-logo">
                                        <img src="/Home/images/logo.png" alt="" className="img-fluid" />
                                    </a>
                                </div>
                                <div className={`col-lg ml-auto header-nav-wrap ${isMobileMenuOpen ? 'show' : ''}`}>
                                    <div className="header-nav js-header-nav">
                                        <nav className="navbar navbar-expand-lg btco-hover-menu">
                                            <div
                                                className={`collapse navbar-collapse justify-content-end ${isMobileMenuOpen ? 'show' : ''}`}
                                                id="navbarNavDropdown">
                                                <ul className="navbar-nav" onClick={closeMobileMenu}>
                                                    <li className="nav-item">
                                                        <NavLink tag={Link} className="nav-link" to="/home" onClick={closeMobileMenu}>Home</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink tag={Link} className="nav-link" to="/about" onClick={closeMobileMenu}>About Us</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink tag={Link} className="nav-link" to="/portfolio" onClick={closeMobileMenu}>Portfolio</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink tag={Link} className="nav-link" to="/Blog" onClick={closeMobileMenu}>Blog</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink tag={Link} className="nav-link" to="/Investor" onClick={closeMobileMenu}>Investor</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink tag={Link} className="nav-link" to="/faq" onClick={closeMobileMenu}>Faq</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink tag={Link} className="nav-link" to="/services" onClick={closeMobileMenu}>Services</NavLink>
                                                    </li>
                                                    <li className="nav-item">
                                                        <NavLink tag={Link} className="nav-link" to="/contactus" onClick={closeMobileMenu}>Contact</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );

}
export default NavMenu;

 