import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import HeaderUser from "./components/HeaderUser";
import Home from "./components/Home";
import FooterUser from "./components/FooterUser";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Blog from "./components/Blog";
import Investor from "./components/Investor";
import FAQs from "./components/FAQs";
import Services from "./components/Services";
import Contactus from "./components/ContactUs";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/headeruser',
    element: <HeaderUser />
  },
  {
    path: '/footeruser',
    element: <FooterUser />
    },
    {
        path: '/about',
        element: <About />
    },
    {
        path: '/portfolio',
        element: <Portfolio />
    },
    {
        path: '/blog',
        element: <Blog />
    },
    {
        path: '/investor',
        element: <Investor />
    },
    {
        path: '/faq',
        element: <FAQs />
    },
    {
        path: '/services',
        element: <Services />
    },
    {
        path: '/contactus',
        element: <Contactus />
    },
];

export default AppRoutes;
