import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const Blog = () => {
    return (
        <>
            <div className="page-content">
                <div className="breadcrumbspage-title">
                    <div className="container">
                        <h2 className="title">Blog</h2>
                        <div className="breadcrumbs">
                            <span><a href="/" rel="home">Home</a></span>
                            <span><i className="fa fa-angle-right"></i></span>
                            <span className="active">Blog</span>
                        </div>
                    </div>
                </div>

                <div className="section page-content-first">
                    <div className="container">
                        <div className="text-center mb-2  mb-md-3 mb-lg-4">
                            <h2>Latest from Our Blog</h2>
                            <div className="h-decor"></div>
                            <div className="text-center mt-2">
                                <p>Stay informed and inspired with the latest trends, insights, and updates from the dynamic world of advertising. Explore our blog for valuable content, industry news, and in-depth articles crafted to elevate your understanding of effective marketing strategies.</p>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row col-equalH">
                                <div className="col-md-6 col-lg-6">
                                    <div className="service-card">
                                        <h5 className="service-card-name"> 1. Navigating the Digital Landscape: Trends and Tips </h5>
                                        <div className="h-decor"></div>
                                        <p>Uncover the latest digital marketing trends and discover actionable tips to enhance your online presence and engage with your audience effectively. </p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="service-card">
                                        <h5 className="service-card-name"> 2. Behind the Scenes: Crafting Compelling Campaigns </h5>
                                        <div className="h-decor"></div>
                                        <p> Gain insights into our creative process and learn how we bring ideas to life. Explore case studies of successful campaigns that captured attention and delivered results. </p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="service-card">
                                        <h5 className="service-card-name"> 3. The Power of Storytelling in Advertising </h5>
                                        <div className="h-decor"></div>
                                        <p> Dive into the art of storytelling and understand how weaving narratives can emotionally connect with your audience, leaving a lasting impression on their minds. </p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="service-card">
                                        <h5 className="service-card-name"> 4. Event Marketing Unveiled: Strategies for Success </h5>
                                        <div className="h-decor"></div>
                                        <p> Explore the intricacies of event marketing, from planning to execution. Learn how to create memorable events that leave a positive impact on your brand. </p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="service-card">
                                        <h5 className="service-card-name"> 5. The Role of Social Media in Modern Advertising </h5>
                                        <div className="h-decor"></div>
                                        <p> Delve into the significance of social media in today's advertising landscape. Discover effective strategies for building and engaging your audience across various platforms. </p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="service-card">
                                        <h5 className="service-card-name"> 6. Adapting to Change: Staying Relevant in Advertising </h5>
                                        <div className="h-decor"></div>
                                        <p> Stay ahead of the curve by understanding the importance of adaptability in the ever-evolving advertising industry. Learn how to pivot and thrive in a rapidly changing landscape. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <p>Partner with Twinbrain Advertising Limited to transform your advertising vision into a compelling reality. We bring expertise, creativity, and dedication to every project, ensuring your brand stands out in today's competitive landscape.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 p-4">
                                <p>Bookmark our blog to stay updated on industry trends and receive valuable insights that can inspire and inform your marketing endeavors. If you have specific topics you'd like us to cover, feel free to reach out—we're here to share knowledge and fuel your success.</p>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        </>
    )
}

export default Blog;