import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const Services = () => {
    return (
		<>
			<div className="page-content">
			<div className="breadcrumbspage-title">
				<div className="container">
					<h2 className="title">Services</h2>
					<div className="breadcrumbs">
						<span><a href="/" rel="home">Home</a></span>
						<span><i className="fa fa-angle-right"></i></span>
						<span className="active">Services</span>
					</div>
				</div>
			</div>

			<div className="section page-content-first">
				<div className="container">
					<div className="text-center mb-2  mb-md-3 mb-lg-4">
						<h1>Services</h1>
						<div className="h-decor"></div>
						<div className="text-center mt-2">
							<p>At Twinbrain Advertising Limited, we offer a comprehensive suite of services designed to elevate your brand, captivate your audience, and drive meaningful engagement. Our team of seasoned professionals is dedicated to delivering tailored solutions that align with your unique business objectives. Explore our range of services:</p>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row col-equalH">
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Strategic Campaign Planning </h5>
								<div className="h-decor"></div>
								<p> Craft dynamic advertising strategies that resonate with your target audience, ensuring maximum impact and brand visibility. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Creative Concept Development </h5>
								<div className="h-decor"></div>
								<p> Unlock the power of creativity with our team of skilled designers and content creators, who breathe life into your brand through visually compelling and emotionally resonant content. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Digital Marketing </h5>
								<div className="h-decor"></div>
								<p> Navigate the digital landscape with confidence. Our digital marketing services encompass SEO, social media management, content marketing, and more to enhance your online presence and boost audience engagement. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Print and Media Advertising </h5>
								<div className="h-decor"></div>
								<p> Leverage the traditional media landscape with eye-catching print ads, radio spots, and television commercials that make a lasting impression. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Event Promotion and Management </h5>
								<div className="h-decor"></div>
								<p> Elevate your brand through memorable events. From planning to execution, we handle every detail to ensure your events leave a lasting impact. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Public Relations (PR) </h5>
								<div className="h-decor"></div>
								<p> Cultivate a positive public image with our PR services. We build and maintain relationships with the media, influencers, and your audience to enhance your brand reputation. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Market Research and Analysis </h5>
								<div className="h-decor"></div>
								<p> Gain valuable insights into your industry and target market. Our research and analysis services help you make informed decisions and stay ahead of the competition. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Brand Consultation and Development </h5>
								<div className="h-decor"></div>
								<p>  Shape and refine your brand identity. Our experts guide you through the process of brand development, ensuring consistency and resonance with your audience. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Social Media Management </h5>
								<div className="h-decor"></div>
								<p> Harness the power of social media to connect with your audience. Our social media management services cover content creation, audience engagement, and performance analysis. </p>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="service-card">
								<h5 className="service-card-name"> Analytics and Performance Tracking </h5>
								<div className="h-decor"></div>
								<p> Measure the impact of your campaigns with precision. Our analytics and performance tracking services provide actionable insights to optimize your advertising strategy. </p>
							</div>
						</div>

					</div>
					<div className="row mt-4">
						<div className="col-md-12">
							<p>Partner with Twinbrain Advertising Limited to transform your advertising vision into a compelling reality. We bring expertise, creativity, and dedication to every project, ensuring your brand stands out in today's competitive landscape.</p>
						</div>
					</div>
				</div>
			</div>
			</div>
        </>
    )
}

export default Services;