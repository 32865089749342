import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const Portfolio = () => {
    return (
        <>
            <div className="page-content">
            <div className="breadcrumbspage-title">
                <div className="container">
                    <h2 className="title">Portfolio</h2>
                    <div className="breadcrumbs">
                        <span><a href="/" rel="home">Home</a></span>
                        <span><i className="fa fa-angle-right"></i></span>
                        <span className="active">Portfolio</span>
                    </div>
                </div>
            </div>

            <div className="section page-content-first about-section mb-0 mt-0">
                <div className="container">
                    <div className="text-center mb-2  mb-md-3 mb-lg-4">
                        <h2>Gallery / Portfolio Showcase</h2>
                        <div className="h-decor"></div>
                        <div className="text-center mt-2">
                            <p>Explore the vibrant and diverse world of advertising through our curated gallery showcasing some of our proudest moments and successful campaigns. Each entry in our portfolio represents a unique blend of creativity, strategy, and client collaboration. Here's a glimpse of what we've accomplished:</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mt-lg-0">
                            <div className="portfolioBox">
                                <div>
                                    <h5 className="icn-text-title"> Digital Marvels  </h5>
                                    <p> Engage with visually stunning digital campaigns that captivated online audiences and drove impactful results. </p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 mt-lg-0">
                            <div className="portfolioBox">
                                <div>
                                    <h5 className="icn-text-title"> Print Perfection  </h5>
                                    <p> Browse through our collection of print advertisements that grabbed attention, leaving a lasting impression in traditional media. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="portfolioBox">
                                <div>
                                    <h5 className="icn-text-title"> Event Extravaganza  </h5>
                                    <p> Experience the excitement of our event management expertise with snapshots from memorable and successful brand events. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="portfolioBox">
                                <div>
                                    <h5 className="icn-text-title"> Social Media Brilliance  </h5>
                                    <p> Witness the power of social media with dynamic and engaging content that fostered meaningful connections with diverse audiences. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="portfolioBox">
                                <div>
                                    <h5 className="icn-text-title"> Brand Transformations  </h5>
                                    <p> Explore before-and-after visuals showcasing how our brand consultation services have transformed and elevated our clients' brand identities. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="portfolioBox">
                                <div>
                                    <h5 className="icn-text-title"> Innovative Campaigns  </h5>
                                    <p> Immerse yourself in the world of innovative advertising campaigns that pushed boundaries and set new standards in the industry.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-lg-0">
                            <div className="portfolioBox">
                                <div>
                                    <h5 className="icn-text-title"> Media Collaborations  </h5>
                                    <p> Discover the impact of our media collaborations, including radio spots, television commercials, and other multimedia ventures. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Portfolio;