import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import HeaderUser from '../components/HeaderUser';
import FooterUser from '../components/FooterUser';
import Slider from '../components/Slider';
import Testimonial from './Testimonial';

const Home = () => {

    const SlickButtonStyles = {
        fontSize: 0,
        lineHeight: 0,
        position: 'absolute',
        top: '44%',
        display: 'block',
        width: '45px',
        height: '45px',
        marginTop: '-23px',
        padding: 0,
        cursor: 'pointer',
        color: '#101010',
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        zIndex: 99,
        textAlign: 'center',
        transition: '0.2s',
        borderRadius: '50%',
        border: '2px solid #fac17f',
    };

    return (
        <>
            <div className="page-content">
                <Slider />
                <div className="section">
                    <div className="container">
                        <div className="title-wrap text-center text-md-left d-lg-none mb-lg-2 animated fadeIn">
                            <h2 className="h1 ">Welcome to 
                                <br className="d-sm-none" />
                                 <span className="theme-color"> Twinbrain Advertisement Limited</span></h2>
                        </div>
                        <div className="row mt-2 mt-md-3 mt-lg-0">
                            <div className="col-md-6 animated bounceIn animatedelay-5s">
                                <div className="title-wrap hidden d-none d-lg-block text-center text-md-left">
                                    <h2 className="lineHeight45">Welcome to <span className="theme-color">Twinbrain Advertisement Limited</span></h2>
                                </div>
                                <div>
                                    <p className="text-justify">Welcome to Twinbrain Advertising Limited, a dynamic player in the advertising industry. Established on 11 February 2022 and headquartered in Nagpur, our company is a Non-govt entity registered with the Registrar of Companies, Mumbai. With an authorized share capital of Rs. 1,000,000 and a paid-up capital of Rs. 675,000, we are committed to excellence in a diverse range of business activities. </p>
                                    <p className="text-justify">Driven by a visionary team, our directors, Nikhil Bhimkumar Wadhwani, Kaustubh Vijay Singh Nayak, and Girish Ramchand Kishnani, steer Twinbrain Advertising towards innovation and success.</p>
                                </div>
                                <div className="mt-1 mt-md-1"></div>
                                <a href="/Home/AboutUs" className="btn-link">Read More<i className="icon-right-arrow"></i></a>
                            </div>
                            <div className="col-md-6 mt-3 mt-md-0">
                                <div className="text-right">
                                    <img src="/Home/images/about/aboutus.jpg" alt="" className="img-fluid aboutImageNew" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="section bg-grey">
                    <div className="container">
                        <div className="title-wrap text-center">
                            <h2 className="h1mainHeading">What Have We Achieved</h2>
                            <div className="h-decor"></div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-4 col-sm-12">
                                <div className="counter-box">
                                    <div className="counter-box-icon"><i className="icon-hand"></i></div>
                                    <h4><span>56</span>m</h4>
                                    <div className="decor"></div>
                                    <div className="counter-box-text">Readers </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="counter-box">
                                    <div className="counter-box-icon"><i className="icon-team"></i></div>
                                    <h4><span>169</span>m</h4>
                                    <div className="decor"></div>
                                    <div className="counter-box-text">Users </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="counter-box">
                                    <div className="counter-box-icon"><i className="icon-first-aid-kit"></i></div>
                                    <h4><span>31</span>m</h4>
                                    <div className="decor"></div>
                                    <div className="counter-box-text">Listeners</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section mb-sm-0 mt-0">
                    <div className="container">
                        <div className="text-center mb-2  mb-md-3 mb-lg-4">
                            <h2>Our Esteemed Clientele</h2>
                            <div className="h-decor"></div>
                            <div className="text-center mt-2">
                                <p>Twinbrain Advertising Limited takes pride in collaborating with a diverse range of clients, contributing to their success through impactful advertising solutions. Our dedication to excellence has earned us the trust of esteemed organizations across various industries. Here are some of our valued clients:</p>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row col-equalH">
                            <div className="col-md-3 col-lg-3">
                                <div className="service-card">
                                    <h5 className="service-card-name"> Tech Innovators </h5>
                                    <div className="h-decor"></div>
                                    <ul className="marker-list-md">
                                        <li>XYZ Tech Solutions </li>
                                        <li>InnovateTech Labs </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="service-card">
                                    <h5 className="service-card-name"> Fashion and Lifestyle </h5>
                                    <div className="h-decor"></div>
                                    <ul className="marker-list-md">
                                        <li>Vogue Trends </li>
                                        <li>Urban Style Co. </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="service-card">
                                    <h5 className="service-card-name"> Health and Wellness </h5>
                                    <div className="h-decor"></div>
                                    <ul className="marker-list-md">
                                        <li>Healthy Living Clinic </li>
                                        <li>NutriWell Supplements </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="service-card">
                                    <h5 className="service-card-name"> Hospitality and Travel </h5>
                                    <div className="h-decor"></div>
                                    <ul className="marker-list-md">
                                        <li>Wanderlust Resorts </li>
                                        <li>Gourmet Getaways </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="service-card">
                                    <h5 className="service-card-name"> Financial Services </h5>
                                    <div className="h-decor"></div>
                                    <ul className="marker-list-md">
                                        <li>Capital Trust Bank </li>
                                        <li>Wealth Dynamics Advisors </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="service-card">
                                    <h5 className="service-card-name"> Educational Institutions </h5>
                                    <div className="h-decor"></div>
                                    <ul className="marker-list-md">
                                        <li>Global Academy of Learning </li>
                                        <li>Scholarly Prep Schools </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="service-card">
                                    <h5 className="service-card-name"> Entertainment and Media </h5>
                                    <div className="h-decor"></div>
                                    <ul className="marker-list-md">
                                        <li>Starlight Productions </li>
                                        <li>MediaMinds Network </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="service-card">
                                    <h5 className="service-card-name"> Non-Profit Organizations </h5>
                                    <div className="h-decor"></div>
                                    <ul className="marker-list-md">
                                        <li>Green Earth Foundation </li>
                                        <li>Harmony for Humanity </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12">
                                <p>
                                    These partnerships reflect our commitment to delivering tailor-made solutions that meet the unique needs of each client. We are grateful for the opportunity to collaborate with such esteemed organizations and look forward to continuing our journey of success together.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Testimonial/>
            </div>
        </>
    )
}

export default Home;